const TOKEN_KEY = 'session';

export const tokenStorage = {
    getToken: (): string | null => {
        return localStorage.getItem(TOKEN_KEY);
    },

    setToken: (token: string): void => {
        localStorage.setItem(TOKEN_KEY, token);
    },

    removeToken: (): void => {
        localStorage.removeItem(TOKEN_KEY);
    },

    parseToken: (token: string): any => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch {
            return null;
        }
    },

    isTokenValid: (token: string): boolean => {
        const payload = tokenStorage.parseToken(token);
        return payload && payload.exp > Date.now() / 1000;
    }
}; 