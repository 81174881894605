/**
 * Environment-specific configuration
 */

const isDevelopment = import.meta.env.DEV;

export const API_BASE = import.meta.env.VITE_PUBLIC_API_BASE || (
    isDevelopment 
        ? 'http://127.0.0.1:8000'
        : 'https://amazon-ads-new-project-855244242565.us-east4.run.app'
);

export const APP_CONFIG = {
    appName: 'Acos Improvers',
    version: '0.1.0',
    isDevelopment
};

export const AUTH_CONFIG = {
    tokenKey: 'session',
    loginEndpoint: '/login',
    logoutEndpoint: '/logout',
    refreshEndpoint: '/refresh',
    callbackEndpoint: '/auth/callback'
};

export const API_ENDPOINTS = {
    user: '/user',
    keywordSearch: '/keyword-search',
    keywordRecommendations: '/keyword-recommendations'
}; 