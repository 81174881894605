<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <!-- <ion-tab-bar slot="bottom" :class="{ 'tab-bar-hidden': isHidden }">
        <ion-tab-button tab="tabs" @click="navigateToTab('/tabs', 0)">
          <ion-icon :icon="homeOutline" />
          <ion-label>Home</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="tab1" @click="navigateToTab('/tabs/tab1', 1)">
          <ion-icon :icon="searchOutline" />
          <ion-label>Keywords</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="tab2" @click="navigateToTab('/tabs/tab2', 2)">
          <ion-icon :icon="analyticsOutline" />
          <ion-label>Profiles</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="tab3" @click="navigateToTab('/tabs/tab3', 3)">
          <ion-icon :icon="settingsOutline" />
          <ion-label>Settings</ion-label>
        </ion-tab-button>
      </ion-tab-bar> -->
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { useIonRouter } from '@ionic/vue';
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonTitle
} from '@ionic/vue';
import {
  homeOutline,
  searchOutline,
  analyticsOutline,
  settingsOutline
} from 'ionicons/icons';
import { APP_CONFIG } from '@/utils/config';

export default defineComponent({
  name: 'TabsPage',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle
  },
  setup() {
    const ionRouter = useIonRouter();
    const currentTab = ref(0);
    const isHidden = ref(false);
    let lastScrollTop = 0;
    let scrollTimeout: number | null = null;

    const handleScroll = () => {
      if (scrollTimeout) {
        window.clearTimeout(scrollTimeout);
      }

      scrollTimeout = window.setTimeout(() => {
        const currentScrollTop = window.scrollY;
        
        // Show tab bar when scrolling up, hide when scrolling down
        if (currentScrollTop > lastScrollTop && currentScrollTop > 50) {
          isHidden.value = true;
        } else {
          isHidden.value = false;
        }
        
        lastScrollTop = currentScrollTop;
      }, 50);
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        window.clearTimeout(scrollTimeout);
      }
    });

    const navigateToTab = (path: string, index: number) => {
      const direction = index > currentTab.value ? 'forward' : 'back';
      currentTab.value = index;
      ionRouter.navigate(path, direction);
    };
    
    return {
      homeOutline,
      searchOutline,
      analyticsOutline,
      settingsOutline,
      APP_CONFIG,
      navigateToTab,
      isHidden
    }
  }
});
</script>

<style scoped>
ion-tab-bar {
  --background: var(--ion-color-light);
  border-top: 1px solid var(--ion-color-light-shade);
  transition: transform 0.3s ease-in-out;
}

ion-tab-button {
  --color-selected: var(--ion-color-primary);
}

ion-icon {
  font-size: 18px;
}

.tab-bar-hidden {
  transform: translateY(100%);
}
</style> 