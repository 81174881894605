<template>
  <ion-menu content-id="main-content" menu-id="main-menu" type="overlay">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeMenu">
            <ion-icon :icon="closeOutline" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-menu-toggle :auto-hide="false">
          <ion-item router-link="/tabs" lines="none">
            <ion-icon :icon="homeOutline" slot="start"></ion-icon>
            <ion-label>Home</ion-label>
          </ion-item>
          <ion-item router-link="/tabs/tab1" lines="none">
            <ion-icon :icon="searchOutline" slot="start"></ion-icon>
            <ion-label>Keywords</ion-label>
          </ion-item>
          <ion-item router-link="/tabs/tab2" lines="none">
            <ion-icon :icon="analyticsOutline" slot="start"></ion-icon>
            <ion-label>Profiles</ion-label>
          </ion-item>
          <ion-item router-link="/tabs/tab3" lines="none">
            <ion-icon :icon="settingsOutline" slot="start"></ion-icon>
            <ion-label>Settings</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts" setup>
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonIcon, IonLabel, IonMenuToggle, IonButtons, IonButton } from '@ionic/vue';
import { searchOutline, analyticsOutline, settingsOutline, closeOutline, homeOutline } from 'ionicons/icons';
import { menuController } from '@ionic/vue';

const closeMenu = () => {
  menuController.close('main-menu');
};
</script>

<style scoped>
ion-menu::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-menu::part(container) {
  border-radius: 0 8px 8px 0;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}

ion-item {
  cursor: pointer;
  --background-hover: var(--ion-color-light);
  --color-hover: var(--ion-color-primary);
}

ion-item:hover ion-icon {
  color: var(--ion-color-primary);
}
</style>