import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/store/auth';

export async function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const authStore = useAuthStore();
  
  // Don't check auth for login and callback routes
  if (to.path === '/login' || to.path === '/auth/callback') {
    next();
    return;
  }
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = await authStore.checkAuthStatus();
    
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
} 