import { defineStore } from 'pinia';
import { checkAuth } from '@/utils/auth/index';
import { tokenStorage } from './persistence/tokenStorage';
import { refreshToken } from './persistence/refreshToken';

interface User {
  id: string;
  email: string;
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    user: null,
    isLoading: false
  } as AuthState),

  getters: {
    isLoggedIn: (state: AuthState) => state.isAuthenticated,
    currentUser: (state: AuthState) => state.user
  },

  actions: {
    setAuthStatus(status: boolean) {
      (this as any).isAuthenticated = status;
    },

    setUser(user: User | null) {
      (this as any).user = user;
    },

    setLoading(status: boolean) {
      (this as any).isLoading = status;
    },

    async checkAuthStatus() {
      this.setLoading(true);
      try {
        const token = tokenStorage.getToken();
        if (!token) {
          this.setAuthStatus(false);
          return false;
        }

        if (!tokenStorage.isTokenValid(token)) {
          const refreshed = await refreshToken.ensureValidToken();
          if (!refreshed) {
            this.setAuthStatus(false);
            return false;
          }
        }

        const payload = tokenStorage.parseToken(token);
        if (payload) {
          this.setUser({
            id: payload.user_id,
            email: payload.username
          });
          this.setAuthStatus(true);
          return true;
        }

        return false;
      } finally {
        this.setLoading(false);
      }
    },

    async logout() {
      this.setUser(null);
      this.setAuthStatus(false);
      tokenStorage.removeToken();
      window.location.href = '/login';
    }
  }
}); 