import { API_BASE } from '@/utils/config';
import { tokenStorage } from './tokenStorage';

export interface RefreshResponse {
    token: string;
}

export const refreshToken = {
    async refresh(): Promise<boolean> {
        try {
            const response = await fetch(`${API_BASE}/refresh`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStorage.getToken()}`
                }
            });

            if (!response.ok) {
                throw new Error('Refresh failed');
            }

            const data = await response.json() as RefreshResponse;
            tokenStorage.setToken(data.token);
            return true;
        } catch (error) {
            console.error('Refresh error:', error);
            return false;
        }
    },

    async ensureValidToken(): Promise<boolean> {
        const token = tokenStorage.getToken();
        if (!token) return false;

        if (!tokenStorage.isTokenValid(token)) {
            return await refreshToken.refresh();
        }

        return true;
    }
}; 