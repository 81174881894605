<template>
  <ion-app>
    <app-menu />
    <app-header v-if="!isLoginRoute && !isAuthCallbackRoute && !isCampaignOverviewRoute" />
    <ion-content>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-content>
  </ion-app>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import { IonApp, IonRouterOutlet, IonContent } from '@ionic/vue';
import { useRoute } from 'vue-router';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppFooter from '@/components/layout/AppFooter.vue';
import AppMenu from '@/components/layout/AppMenu.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonContent,
    AppHeader,
    AppFooter,
    AppMenu
  },
  setup() {
    const route = useRoute();
    
    const initializeTheme = () => {
      const storedPreference = localStorage.getItem('darkMode');
      if (storedPreference !== null) {
        const isDark = storedPreference === 'true';
        document.documentElement.classList.toggle('dark', isDark);
      } else {
        // Default to dark mode
        document.documentElement.classList.toggle('dark', true);
        localStorage.setItem('darkMode', 'true');
      }
    };

    onMounted(() => {
      initializeTheme();
    });
    
    const isTabsRoute = computed(() => {
      return route.path.startsWith('/tabs');
    });

    const isLoginRoute = computed(() => {
      return route.path === '/login';
    });

    const isAuthCallbackRoute = computed(() => {
      return route.path === '/auth/callback';
    });

    const isCampaignOverviewRoute = computed(() => {
      return route.name === 'CampaignOverview';
    });

    return {
      isTabsRoute,
      isLoginRoute,
      isAuthCallbackRoute,
      isCampaignOverviewRoute
    };
  }
});
</script>

<style>
/* Global styles */
:root {
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
}

.page-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

ion-content {
  --padding-top: var(--ion-safe-area-top);
  --padding-bottom: var(--ion-safe-area-bottom);
}
</style>
